import React from 'react'
import {Link, graphql, PageProps} from 'gatsby'
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import Gallery from '../components/gallery'
import Section from '../components/section'
import Textbox from '../components/textbox'
import {Vimeo, YouTube} from '../components/video'
import {Dot} from '../components/icons'
import ContactLinks from '../modules/contact'

const IndexPage = ({data, location}: PageProps<Queries.HomepageQuery>) => {
	const headshots = data?.headshots?.items?.filter(Boolean) as Contentful.Headshot[] ?? []
	const videos = data?.videos?.items?.filter(Boolean) as Contentful.Video[] ?? []
	const stills = imagesFromData(/^STILL_/)

	return <StandardLayout location={location}>
		<Helmet>
			<body className="home-page" />
		</Helmet>

		<h1 className="card-style-block-text">
			<strong>Tim</strong>
			<br /> Shelburne
		</h1>
		<hr />
		<h2>
			actor <Dot /> filmmaker <Dot /> musician
		</h2>

		<Section>
			<Gallery
				className="headshot-gallery"
				images={headshots.map((headshot) => headshot.image)}
			/>
			<Link to="/headshots" className="cta-link">
				More Headshots
			</Link>
		</Section>

		{videos.map(video => (
			<Section key={video.videoId} dark title={video.name}>
				{video.source === `Vimeo` && <Vimeo id={video.videoId} />}
				{video.source === `YouTube` && <YouTube id={video.videoId} />}
				<h3>{video.caption}</h3>
				{video.description?.description}
			</Section>
		))}

		<Section title="Hi, I'm Tim">
			<Textbox>
				<blockquote>An actor who can disappear into dynamic and emotionally charged roles.</blockquote>
				<p>
					Best exemplified in Netflix's "Here Love Lies" with a shocking transformation 
					from vulnerable rom-com love interest to a calculating serial killer, I have a 
					reputation for always delivering and cultivating deep skills in martial arts, 
					fluency in multiple languages, and a distinctly "yes-and" attitude to life.
				</p>
				<p>
					Most recently, I've been honored with a nomination for Best Actor in the Best of Nollywood
					Awards for the lead in "Here Love Lies" (dir. Tope Oshin), seen on Lifetime as the leading 
					man in "The Perfect In-Laws" (dir. Ashley Jones), and winner of Best Actor in the Milan
					IFF for the lead role in "Chinese Laundry" (dir. Giorgio Arcelli), which is on the shortlist 
					for the Oscars Best Shorts. I also appeared in "Rumba Love" (Showtime), "A Merry Single 
					Christmas" (Peacock), "FBI: Most Wanted" (CBS), and "Mind Over Murder" (HBO).
				</p>
				<hr />
				<p>I like to do everything.</p>
				<p>
					I'm an actor, a filmmaker, a musician, a fighter, a climber, a traveler, a rower, a 
					problem-solver, a developer, a student, a teacher, a gamer, a thinker, a tinkerer, a dancer, 
					an opera singer, and an animal person.
				</p>
				<p>
					I've built my own bass guitar from wood on my grandfather's farm, I've lived in three 
					different countries, I've rowed a marathon, I've translated directly from Chinese into 
					Russian in an airport under pressure, and I've rolled my own cigars. I've toured as an 
					indie musician from El Paso to Boston, performed in the Austin Opera, and learned to tap 
					dance for a run of Anything Goes.
				</p>
			</Textbox>
			<Link to="/contact" className="cta-link">
				Let's work together
			</Link>
		</Section>

		<Section dark title="Stills Gallery">
			<Gallery
				className="stills-gallery"
				images={stills}
			/>
			<Link to="/stills" className="cta-link">
				More Stills
			</Link>
		</Section>

		<footer>
			<ContactLinks />
		</footer>
	</StandardLayout>

	function imagesFromData(prefix: RegExp) {
		return Object.entries(data)
			.filter(([k]) => k.match(prefix))
			.map(([title, file]) => {
				console.log(title, file)
				const cleanTitle = title
					.replace(prefix, '')
					.replace(/[a-z][A-Z]/g, (s) => `${s[0]} ${s[1].toLowerCase()}`)
					.replace(/[a-z][A-Z]/g, (s) => s.toLowerCase())

				return {
					title: `${cleanTitle} (${file?.name})`,
					url: file?.publicURL,
					image: file?.childImageSharp?.gatsbyImageData,
				}
			})
	}
}

export default IndexPage

export const query = graphql`
	fragment ImageFile on File {
		name
		publicURL
		childImageSharp {
			gatsbyImageData(width: 700)
		}
	}

	query Homepage {
		videos: contentfulOrdering(name: {eq: "Homepage Videos"}) {
			items {
				... on ContentfulVideo {
					name
					caption
					source
					videoId
					description {
						description
					}
				}
			}
		}
		headshots: contentfulOrdering(name: {eq: "Homepage Headshots"}) {
			items {
				... on ContentfulHeadshot {
					name
					image {
						title
						url
						image: gatsbyImageData(width: 350)
					}
				}
			}
		}

		STILL_reel2realCU: file(sourceInstanceName: {eq: "src"}, name: {eq: "R2R4"}) { ...ImageFile }
		STILL_guilleFight: file(sourceInstanceName: {eq: "src"}, name: {eq: "LAW1"}) { ...ImageFile }
		STILL_gunsRaised: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS8"}) { ...ImageFile }
		STILL_gretchenCU: file(sourceInstanceName: {eq: "src"}, name: {eq: "Gretchen2"}) { ...ImageFile }
		STILL_dontMakeMeShoot: file(sourceInstanceName: {eq: "src"}, name: {eq: "WFO2"}) { ...ImageFile }
		STILL_aLittleDark: file(sourceInstanceName: {eq: "src"}, name: {eq: "HLL7"}) { ...ImageFile }
		STILL_sadFather: file(sourceInstanceName: {eq: "src"}, name: {eq: "dodgson2"}) { ...ImageFile }
		STILL_cockyBusinessGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "R2R3"}) { ...ImageFile }
	}
`
