import React from 'react'

export const Dot = () => <UnicodeIcon code={toHexString('B7')} />

// HELPERS

interface UnicodeIconProps {
    code: HexString | number
}

const UnicodeIcon = ({code}: UnicodeIconProps) => {
    const codeHex = `&#x${typeof code === 'number' ? code.toString(16) : code};`
    return <span dangerouslySetInnerHTML={{ __html: codeHex }}></span>
}

type HexString = { __TSType: HexString } & string

function toHexString(v: string | number): HexString {
    if (typeof v === 'number') return v.toString(16) as unknown as HexString
    if (isHexString(v)) return v
    throw new Error(`${v} is not a valid hex string`) 
}

function isHexString(v: string): v is HexString {
    return /^[0123456789ABCDEFabcdef]*$/.test(v)
}